import * as React from 'react'
import { useEffect } from 'react'

const BookingButton = ({ type='all', children='Termin buchen' }) =>
{
  
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://meetings.brevo.com/assets/libs/popup.min.js";
        script.type = "text/javascript";
        script.onload = () => {
            document.getElementById('bookappointmentbtn').addEventListener('click', () => {
                if (window.BrevoBookingPage) window.BrevoBookingPage.initStaticButton({ url: `https://meet.brevo.com/jorg-brandeis/borderless?l=${type}` });
                return false; 
            });
        };
        document.body.appendChild(script);
    }, []);

    return (
        <div>
            <link href="https://meetings.brevo.com/assets/styles/popup.css" rel="stylesheet" />

{/* Script not executed and BrevoBookingPage cant be found in useEffect */}
            {/* <Script src="https://meetings.brevo.com/assets/libs/popup.min.js" type="text/javascript"></Script> */}

            <span id="bookappointmentbtn" style={{cursor: 'pointer', fontFamily: 'Inter', fontWeight: 500, backgroundColor: '#1B1B1B', color: 'white', padding: '0.5rem 1rem', border: '0px', boxShadow: 'rgba(0, 0, 0, 0.15) 0px -2px 0px inset', borderRadius: '16px', textDecoration: 'none', display: 'inline-block'}}>
            {children}
            </span>
        </div>
    )  
}

export default BookingButton;